import * as React from "react"
import {useContext, useEffect, useState} from "react"
import {graphql, Link} from "gatsby"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import Axios from "axios"

import Layout from "../../../../components/Layout/layout"
import Seo from "../../../../components/seo"
import Search from "../../../../components/Concrete/search"
import Listing from "../../../../components/Concrete/listing"
import PopularServices from "../../../../components/Concrete/popularServices"
import OurServices from "../../../../components/Concrete/ourServices"
import Gallery from "../../../../components/Concrete/gallery"
import AIArticleSection from "../../../../components/Concrete/aiArticleSection"
import Hero from "../../../../components/Concrete/hero"
import Faq from "../../../../components/Concrete/faq"

import "../../../../css/local_list.css"
import { setCityImage } from "../../../../services/imageHelper"
import Moment from "react-moment";
import Map from "../../../../components/Concrete/map";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Slider from "react-slick";
import LocationContext from "../../../../components/Location/location";

const { slugify, unSlugify } = require("../../../../services/slugHelper")

const ConcretePage = props => {
  const [basePath, setBasePath] = useState(""),
    [extraInformation, setExtraInformation] = useState({})

  const getTimeZoneOffset = () => {
    const current_date = new Date()
    return -current_date.getTimezoneOffset() / 60
  }

  const category = typeof props.category !== "undefined" ? unSlugify(props.category) : ""
  const cityName = typeof props.city !== "undefined" ? unSlugify(props.city) : ""
  const regionCode = props.region

  let cityData = props.data.allCity.nodes.filter(city => cityName === city.name)
  cityData = cityData.length > 0 ? cityData[0] : ""
  let regionData = props.data.allRegion.nodes.filter(region => regionCode === region.code.toLowerCase())
  regionData = regionData.length > 0 ? regionData[0] : ""

  useEffect(() => {
    document.body.classList.add("ClientToVendor")

    const countryCode = props.country.toUpperCase()
    const regionCode = props.region.toUpperCase()

    let location = {}
    location.countryCode = countryCode
    location.regionCode = regionCode

    setBasePath(("/" + countryCode + "/" + regionCode + "/" + slugify(cityName)).toLowerCase())

    setExtraInformation({
      language: window.navigator.language,
      currentUrl: window.location.href,
      timeZone: `GMT + ${getTimeZoneOffset()}`,
      browserResolution: window.screen.height + "x" + window.screen.width,
    })

    setCityImage(
      location,
      `${process.env.GATSBY_WRANGLER_URL}/${countryCode}/${regionCode}/${props.city}.jpg`
    )
  }, [props, cityName, category])

  const [address, setAddress] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = address => {
    setAddress(address)
  }

  const handleSelect = address => {
    setAddress(address)
    geocodeByAddress(address)
      .then(results => {
        document.getElementsByClassName("location-search-input")[0].value = address
        return getLatLng(results[0])
      })
      .catch(error => console.error("Error", error))
  }

  const handleSubmit = e => {
    e.preventDefault()
    var formData = new FormData(e.target)
    var url = document.location.href
    if (url.indexOf("?") !== -1) {
      var qs = url.substring(url.indexOf("?") + 1).split("&")
      if (qs.length > 0) {
        for (var i = 0; i < qs.length; i++) {
          qs[i] = qs[i].split("=")
          formData.append(qs[i][0], decodeURIComponent(qs[i][1]))
        }
      }
    }

    const payload = Object.fromEntries(formData)
    if (typeof payload.name === "undefined" || payload.name === "") {
      return false
    }
    if (typeof payload.email_spam === "undefined" || payload.email_spam === "") {
      return false
    }
    setIsLoading(true)
    payload.address = {
      address: payload.address,
      permission: "public",
      type: "home",
    }
    const apiURL = `${process.env.GATSBY_BACKEND_API_URL}/drive/form-handling/add`
    Axios.post(`${apiURL}`, payload, {
      "Content-Type": "application/json",
    })
      .then(function (response) {
        setIsLoading(false)
        if (response.data.formHandling.response === "error") {
          alert("Please try again!")
          return false
        }
        alert("Your request has been submitted successfully!")
        window.location.reload()
      })
      .catch(function (error) {
        alert("Please try again!")
        setIsLoading(false)
      })
  }

  let city = cityName
  let region = regionData
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  }

  const location = useContext(LocationContext)
  let locationName = typeof city === "undefined" ? location.city : city

  return (
    <Layout locationName={cityName} region={regionData} basePath={basePath}>
      <Seo
        description={`${cityName} Concrete - Free Quote - Stamped, Driveways, Patios, Crack Repair, Decorative, Polished, Foundation Work, Removal, Bars & Counters. Price range for ${cityName} Concrete`}
        title={`CONCRETE Contractors / Concrete Company in ${cityName}, ${regionData.name}`}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div
              className="heroSection jumbotron text-center mb-0 d-flex flex-column justify-content-center position-relative"
              style={{ backgroundImage: `url(${process.env.GATSBY_WRANGLER_URL}/movers/movers.jpg)` }}
            >
              <img
                src={process.env.GATSBY_STATIC_IMAGE_URL + "/charleston-handyman.png"}
                alt=""
                className="category_avatar"
              />
              <div className="row g-0 align-items-center">
                <div className="col-lg-12 px-0 px-md-3">
                  <div className="row g-0">
                    <div className="col-md-8 mx-auto cat-box-info">
                      <h2 className="display-2 fw-bold"> {city} Concrete Services</h2>
                      <div className="pt-md-5 pt-3">
                        <div className="FrmSearch">
                          <div className="row">
                            <div className="col-xl-9 col-lg-9 mx-auto">
                              <h3 className="mb-3 VendorToClientLabel">
                                Where do you need your Auto Mechanic service?
                              </h3>
                              <h3 className="mb-3 ClientToVendorLabel">
                                Fast, Easy, Free Quotes on your Concrete Project
                                <span className="arrow-down">
                                <i className="bi bi-arrow-90deg-down" />
                              </span>
                              </h3>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-10 col-lg-10 mx-auto">
                              <div className="d-none d-md-block">
                                <form className="FrmQuote" method="post" onSubmit={handleSubmit}>
                                  <PlacesAutocomplete
                                    value={address}
                                    onChange={handleChange}
                                    onSelect={handleSelect}
                                  >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                      <>
                                        <div className="input-group">
                                          <input
                                            {...getInputProps({
                                              name: "address",
                                              placeholder: "What's your home address?",
                                              className: "form-control form-control-lg location-search-input",
                                              id: "heroSectionAddressFieldDesktop",
                                            })}
                                          />
                                          {address && (
                                            <>
                                              <input
                                                type="text"
                                                name="name"
                                                className="form-control form-control-lg inputFieldAfterAddress active"
                                                placeholder="Your Name"
                                                required
                                              />
                                              <input
                                                type="email"
                                                name="email_spam"
                                                className="form-control form-control-lg inputFieldAfterAddress active"
                                                placeholder="Email"
                                                required
                                              />
                                              <input type="email" name="email" className={"d-none"} />
                                            </>
                                          )}
                                          <button
                                            className="btn btn-lg btnSearch btnSubmit"
                                            type="submit"
                                            data-text="Get My Price Quote"
                                            disabled={isLoading}
                                          >
                                            {isLoading && "Sending..."}
                                            {!isLoading && (
                                              <>
                                                Get My Price Quote <i className="bi bi-check2" />
                                              </>
                                            )}
                                          </button>
                                          <button type="reset" className="btnFormReset d-none">
                                            Reset
                                          </button>
                                          <input
                                            type="hidden"
                                            name="timezone"
                                            defaultValue={extraInformation.timeZone}
                                          />
                                          <input
                                            type="hidden"
                                            name="language"
                                            defaultValue={extraInformation.language}
                                          />
                                          <input
                                            type="hidden"
                                            name="capturepage"
                                            defaultValue={extraInformation.currentUrl}
                                          />
                                          <input
                                            type="hidden"
                                            name="browserResolution"
                                            defaultValue={extraInformation.browserResolution}
                                          />
                                          <input type="hidden" name="form_type" defaultValue="B2B" />
                                          <input
                                            name="formId"
                                            id="formId"
                                            defaultValue="13F110"
                                            type="hidden"
                                          />
                                          <input
                                            name="referer"
                                            defaultValue="https://swiftlocal.com/"
                                            type="hidden"
                                          />
                                          <input
                                            name="spam_reduction"
                                            defaultValue="https://swiftlocal.com/"
                                            type="hidden"
                                          />
                                        </div>
                                        <div
                                          className="autocomplete-dropdown-container"
                                          style={{ paddingTop: "5px" }}
                                        >
                                          {loading && <div>Loading...</div>}
                                          {suggestions.map(suggestion => {
                                            let className = "suggestion-item"
                                            let style = {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                              padding: "5px",
                                              color: "#000",
                                              textAlign: "left",
                                            }
                                            if (suggestion.active) {
                                              style.backgroundColor = "#5e9ad7"
                                              className = "suggestion-item--active"
                                            }
                                            return (
                                              <div
                                                {...getSuggestionItemProps(suggestion, {
                                                  className,
                                                  style,
                                                })}
                                              >
                                              <span>
                                                <i className="bi bi-geo-alt-fill" />
                                                {` ${suggestion.description}`}
                                              </span>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </>
                                    )}
                                  </PlacesAutocomplete>
                                </form>
                              </div>
                              <div className="d-block d-md-none text-start">
                                <form className="FrmQuote" method="post">
                                  <input
                                    type="text"
                                    name="address"
                                    className="form-control form-control-lg mb-2"
                                    id="heroSectionAddressFieldMobile"
                                    placeholder="Enter your home address"
                                    required
                                  />
                                  <input
                                    type="text"
                                    name="name"
                                    className="form-control form-control-lg mb-2 inputFieldAfterAddress"
                                    placeholder="Your Name"
                                    required
                                  />
                                  <input
                                    type="email"
                                    name="email_spam"
                                    className="form-control form-control-lg mb-2 inputFieldAfterAddress"
                                    placeholder="Email"
                                    required
                                  />
                                  <input type="email" name="email" className="d-none" />
                                  <button
                                    className="btn btn-lg btnSearch btnSubmit"
                                    type="submit"
                                    data-text="Get My Price Quote"
                                  >
                                    Get My Price Quote <i className="bi bi-check2" />
                                  </button>
                                  <button type="reset" className="btnFormReset d-none">
                                    Reset
                                  </button>
                                  <input type="hidden" name="timezone" />
                                  <input type="hidden" name="language" />
                                  <input type="hidden" name="capturepage" />
                                  <input type="hidden" name="browserResolution" />
                                  <input type="hidden" name="form_type" value="B2B" />
                                  <input name="formId" id="formId" value="13F110" type="hidden" />
                                  <input name="referer" value="https://swiftlocal.com/" type="hidden" />
                                  <input name="spam_reduction" value="https://swiftlocal.com/" type="hidden" />
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="listing_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12" />
            <div className="col-lg-12 listing_container">
              <div className="row">
                <div className="col-lg-8 position-relative listing_left_section">
                  <div className="cityImgMapContainer">
                    <div className="cityImgContainer hero_section" />
                    <div id="hero-map-mobile" />
                  </div>

                  <h1 className="listing_headline text-center">
                    Best Concrete Services in {city}, {region.code} as of <Moment format="MMM" />,{" "}
                    {<Moment format="YYYY" />}{" "}
                  </h1>

                  <section className="companyReviewSection mt-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center mb-3">
                          <h2>Recent Reviews...</h2>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div id="testimonialControls" className="slide">
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <div className="testimonial_userinfo">
                                  <img
                                    src={`${process.env.GATSBY_STATIC_IMAGE_URL}/Michael-A.jpg`}
                                    className="img-fluid"
                                    alt="Michael-A.jpg"
                                  />
                                  <div className="testimonial_username">
                                    <strong>Michael A.</strong>
                                    <div className="content-stars">
                                      <span className="pro-rating">5.0</span>
                                      <i className="bi bi-star-fill stars active" />
                                      <i className="bi bi-star-fill stars active" />
                                      <i className="bi bi-star-fill stars active" />
                                      <i className="bi bi-star-fill stars active" />
                                      <i className="bi bi-star-fill stars active" />
                                    </div>
                                  </div>
                                </div>
                                <p>
                                  <strong>Exceptional Craftsmanship!</strong>
                                </p>
                                <p>
                                  From the first consultation to the final product, the quality and
                                  professionalism stood out. Our new driveway has become the envy of the
                                  neighborhood. Highly recommend these experts!
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <div id="testimonialControls2" className="slide">
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <div className="testimonial_userinfo">
                                  <img
                                    src={`${process.env.GATSBY_STATIC_IMAGE_URL}/Patricia-H.jpg`}
                                    className="img-fluid"
                                    alt="-H.jpg"
                                  />
                                  <div className="testimonial_username">
                                    <strong>Patricia H</strong>
                                    <div className="content-stars">
                                      <span className="pro-rating">5.0</span>
                                      <i className="bi bi-star-fill stars active" />
                                      <i className="bi bi-star-fill stars active" />
                                      <i className="bi bi-star-fill stars active" />
                                      <i className="bi bi-star-fill stars active" />
                                      <i className="bi bi-star-fill stars active" />
                                    </div>
                                  </div>
                                </div>
                                <p>
                                  <strong>Reliable and Efficient!</strong>
                                </p>
                                <p>
                                  I wasn't sure what to expect, but their team made everything so easy. The
                                  stamped patio work is stunning and was completed ahead of schedule. Five
                                  stars!
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="col-lg-4" id="sticky_sidebar">
                  {cityData && <Map cityData={cityData} />}
                  <div className="profile-contactbox" id="quote-form">
                    <h3 className="contactBoxHeading">Get Your Price Quote</h3>
                    <div className="contactBoxInner">
                      <form className="FrmQuote" method="post" onSubmit={handleSubmit}>
                        <input type="hidden" name="timezone" defaultValue={extraInformation.timeZone} />
                        <input type="hidden" name="language" defaultValue={extraInformation.language} />
                        <input type="hidden" name="capturepage" defaultValue={extraInformation.currentUrl} />
                        <input
                          type="hidden"
                          name="browserResolution"
                          defaultValue={extraInformation.browserResolution}
                        />
                        <input type="hidden" name="form_type" defaultValue="B2B" />
                        <input name="formId" id="formId" defaultValue="13F110" type="hidden" />
                        <input name="referer" defaultValue="https://swiftlocal.com/" type="hidden" />
                        <input name="spam_reduction" defaultValue="https://swiftlocal.com/" type="hidden" />
                        <div className="mb-3">
                          <label className="form-label" htmlFor="ServiceNeeded1">
                            Service Needed
                          </label>
                          <div className="mb-1">
                            <input
                              type="checkbox"
                              name="ServiceNeeded"
                              id="ServiceNeeded1"
                              className="css-checkbox"
                              value="Stamped Concrete"
                            />
                            <label htmlFor="ServiceNeeded1" className="css-checkbox-label">
                              Stamped Concrete
                            </label>
                          </div>
                          <div className="mb-1">
                            <input
                              type="checkbox"
                              name="ServiceNeeded"
                              id="ServiceNeeded2"
                              className="css-checkbox"
                              value="Concrete Patio"
                            />
                            <label htmlFor="ServiceNeeded2" className="css-checkbox-label">
                              Concrete Patio
                            </label>
                          </div>
                          <div className="mb-1">
                            <input
                              type="checkbox"
                              name="ServiceNeeded"
                              id="ServiceNeeded3"
                              className="css-checkbox"
                              value="Concrete Driveway"
                            />
                            <label htmlFor="ServiceNeeded3" className="css-checkbox-label">
                              Concrete Driveway
                            </label>
                          </div>
                          <div className="mb-1">
                            <input
                              type="checkbox"
                              name="ServiceNeeded"
                              id="ServiceNeeded4"
                              className="css-checkbox"
                              value="Other"
                            />
                            <label htmlFor="ServiceNeeded4" className="css-checkbox-label">
                              Other
                            </label>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="size">
                            Approximate Size (Sq Ft) of Project
                          </label>
                          <select className="form-select" id="size" name="size">
                            <option value="Less than 50 Sq Ft">Less than 50 Sq Ft</option>
                            <option value="50 - 100 Sq Ft">50 - 100 Sq Ft</option>
                            <option value="More than 100 Sq Ft">More than 100 Sq Ft</option>
                          </select>
                        </div>

                        <div className="signup-or-separator">
                          <span className="signup-or-separator--text">About You</span>
                          <hr />
                        </div>

                        <div className="mb-3">
                          <input
                            type="text"
                            className="form-control reqField"
                            name="name"
                            id="reqName"
                            placeholder="Name"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            type="email"
                            name="email_spam"
                            className="form-control reqField"
                            placeholder="Email"
                            required
                          />
                          <input type="email" name="email" className="d-none" />
                        </div>
                        <div className="mb-3">
                          <div className="row">
                            <div className="col-lg-6 col-md-9">
                              <input
                                type="text"
                                className="form-control swift_phone_field_us reqField"
                                name="phone"
                                id="reqPhone"
                                placeholder="Phone"
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-3">
                              <input
                                type="checkbox"
                                name="SMS"
                                id="ListCheckbox"
                                className="css-checkbox"
                                value="1"
                                defaultChecked
                              />
                              <label htmlFor="ListCheckbox" className="css-checkbox-label radGroup2 mb-0">
                                OK to SMS{" "}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>
                                      SMS messages will be regarding your local services only and is not resold.
                                      Reply STOP anytime to opt out. By submitting this form, you grant
                                      permission for us and our service partner to send transactional SMS
                                      messages.
                                    </Tooltip>
                                  }
                                >
                                  <i className="bi bi-question-circle-fill" />
                                </OverlayTrigger>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <>
                                <div className="input-group">
                                  <input
                                    {...getInputProps({
                                      name: "address",
                                      placeholder: "Address",
                                      className: "form-control reqField",
                                      id: "reqAddress",
                                      required: "required",
                                    })}
                                  />
                                </div>
                                <div className="autocomplete-dropdown-container" style={{ paddingTop: "5px" }}>
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map(suggestion => {
                                    let className = "suggestion-item"
                                    let style = {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                      padding: "5px",
                                      color: "#000",
                                      textAlign: "left",
                                    }
                                    if (suggestion.active) {
                                      style.backgroundColor = "#5e9ad7"
                                      className = "suggestion-item--active"
                                    }
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                      <span>
                                        <i className="bi bi-geo-alt-fill" />
                                        {` ${suggestion.description}`}
                                      </span>
                                      </div>
                                    )
                                  })}
                                </div>
                              </>
                            )}
                          </PlacesAutocomplete>
                        </div>

                        <div className="mb-2">
                          <button type="reset" className="btnFormReset d-none">
                            Reset
                          </button>
                          <button
                            type="submit"
                            className="btn btn-local btn-lg w-100 btnSubmit"
                            disabled={isLoading}
                          >
                            {isLoading && "Sending..."}
                            {!isLoading && (
                              <>
                                Get My Price Quote <i className="bi bi-check2" />
                              </>
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ourServicesSection bg-fafafa" id="services">
        <div className="container">
          <h2 className="listing_headline text-center">
            {city}, {region.code} Concrete Company & Concrete Contractors
          </h2>

          <ul className="nav nav-tabs align-items-end" id="ourServicesTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="tab1-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab1-tab-pane"
                type="button"
                role="tab"
                aria-controls="tab1-tab-pane"
                aria-selected="true"
              >
                Stamped
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tab2-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab2-tab-pane"
                type="button"
                role="tab"
                aria-controls="tab2-tab-pane"
                aria-selected="false"
              >
                Driveways
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tab3-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab3-tab-pane"
                type="button"
                role="tab"
                aria-controls="tab3-tab-pane"
                aria-selected="false"
              >
                Patios
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tab4-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab4-tab-pane"
                type="button"
                role="tab"
                aria-controls="tab4-tab-pane"
                aria-selected="false"
              >
                Crack Repair
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tab5-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab5-tab-pane"
                type="button"
                role="tab"
                aria-controls="tab5-tab-pane"
                aria-selected="false"
              >
                Decorative
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tab6-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab6-tab-pane"
                type="button"
                role="tab"
                aria-controls="tab6-tab-pane"
                aria-selected="false"
              >
                Polished
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tab7-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab7-tab-pane"
                type="button"
                role="tab"
                aria-controls="tab7-tab-pane"
                aria-selected="false"
              >
                Foundation Work
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tab8-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab8-tab-pane"
                type="button"
                role="tab"
                aria-controls="tab8-tab-pane"
                aria-selected="false"
              >
                Removal
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="tab9-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab9-tab-pane"
                type="button"
                role="tab"
                aria-controls="tab9-tab-pane"
                aria-selected="false"
              >
                Bars & Counters
              </button>
            </li>
          </ul>
          <div className="tab-content" id="ourServicesTabContent">
            <div
              className="tab-pane fade show active"
              id="tab1-tab-pane"
              role="tabpanel"
              aria-labelledby="tab1-tab"
              tabIndex="0"
            >
              <div className="row">
                <div className=" col-sm-3">
                  <img
                    src={`${process.env.GATSBY_STATIC_IMAGE_URL}/services/stamped.jpg`}
                    alt="Stamped Concrete"
                    className="img-fluid"
                  />
                </div>
                <div className="mt-3 mt-sm-0 col-sm-9">
                  <p>
                    Transform your outdoor space in {city} with our premier stamped concrete services. Stamped
                    concrete brings the beauty of intricate designs and patterns, replicating the appearance of
                    natural stone, brick, or even wood. Whether you desire a cobblestone pathway or a wood-grain
                    patio, our team crafts designs that combine both aesthetics and durability, ensuring a
                    long-lasting impression for years to come.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tab2-tab-pane"
              role="tabpanel"
              aria-labelledby="tab2-tab"
              tabIndex="0"
            >
              <div className="row">
                <div className=" col-sm-3">
                  <img
                    src={`${process.env.GATSBY_STATIC_IMAGE_URL}/services/driveway.jpg`}
                    alt="Concrete Driveways"
                    className="img-fluid"
                  />
                </div>
                <div className="mt-3 mt-sm-0 col-sm-9">
                  <p>
                    As the leading driveway specialists in {city}, we understand the importance of a
                    well-constructed driveway that not only stands the test of time but also elevates your
                    property's curb appeal. Our skilled team will work closely with you, employing the best
                    materials and techniques, to deliver a driveway that combines functionality, durability, and
                    design.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tab3-tab-pane"
              role="tabpanel"
              aria-labelledby="tab3-tab"
              tabIndex="0"
            >
              <div className="row">
                <div className=" col-sm-3">
                  <img
                    src={`${process.env.GATSBY_STATIC_IMAGE_URL}/services/patio.jpg`}
                    alt="Concrete Patios"
                    className="img-fluid"
                  />
                </div>
                <div className="mt-3 mt-sm-0 col-sm-9">
                  <p>
                    Your backyard in {city} can be transformed into an outdoor oasis with our exceptional
                    concrete patio services. Tailoring each project to fit our client's vision, we ensure that
                    every patio we build becomes the perfect space for relaxation, entertainment, and making
                    memories. Let us help you craft an outdoor extension of your living space that harmoniously
                    blends with your landscape.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tab4-tab-pane"
              role="tabpanel"
              aria-labelledby="tab4-tab"
              tabIndex="0"
            >
              <div className="row">
                <div className=" col-sm-3">
                  <img
                    src={`${process.env.GATSBY_STATIC_IMAGE_URL}/services/pexels-eva-bronzini-6140926.jpg`}
                    alt="Crack Repair"
                    className="img-fluid"
                  />
                </div>
                <div className="mt-3 mt-sm-0 col-sm-9">
                  <p>
                    Cracks in concrete can lead to significant issues down the road if not addressed promptly.
                    Serving the {city} community, we specialize in comprehensive crack repair solutions. Our
                    team employs state-of-the-art techniques to mend cracks, ensuring the longevity of your
                    concrete surfaces and restoring their original strength and beauty.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tab5-tab-pane"
              role="tabpanel"
              aria-labelledby="tab5-tab"
              tabIndex="0"
            >
              <div className="row">
                <div className=" col-sm-3">
                  <img
                    src={`${process.env.GATSBY_STATIC_IMAGE_URL}/services/pexels-ksenia-chernaya-5691606.jpg`}
                    alt="Decorative Concrete"
                    className="img-fluid"
                  />
                </div>
                <div className="mt-3 mt-sm-0 col-sm-9">
                  <p>
                    Reimagine the potential of concrete with our decorative services in {city}. From vibrant
                    color stains to intricate textures, we provide a plethora of options to customize your
                    surfaces. Perfect for both residential and commercial spaces, our decorative concrete
                    solutions offer an artistic touch that is both unique and long-lasting.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tab6-tab-pane"
              role="tabpanel"
              aria-labelledby="tab6-tab"
              tabIndex="0"
            >
              <div className="row">
                <div className=" col-sm-3">
                  <img
                    src={`${process.env.GATSBY_STATIC_IMAGE_URL}/services/pexels-david-brown-17410735.jpg`}
                    alt="Polished Concrete"
                    className="img-fluid"
                  />
                </div>
                <div className="mt-3 mt-sm-0 col-sm-9">
                  <p>
                    For a sleek and modern finish, our polished concrete services in {city} are second to none.
                    By refining your concrete surfaces to a glossy sheen, we provide a solution that is not just
                    aesthetically appealing but also resistant to wear and easy to maintain. It's a perfect
                    choice for both homes and businesses seeking a stylish yet durable flooring option.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tab7-tab-pane"
              role="tabpanel"
              aria-labelledby="tab7-tab"
              tabIndex="0"
            >
              <div className="row">
                <div className=" col-sm-3">
                  <img
                    src={`${process.env.GATSBY_STATIC_IMAGE_URL}/services/pexels-cottonbro-studio-7109818.jpg`}
                    alt="Foundation Work"
                    className="img-fluid"
                  />
                </div>
                <div className="mt-3 mt-sm-0 col-sm-9">
                  <p>
                    Every structure in {city} requires a strong foundation, and that's where our expertise
                    shines. Our team understands the nuances of foundation work, ensuring that your construction
                    stands firm against the test of time. From initial excavation to the final pour, we manage
                    each step with precision and care, ensuring the safety and stability of your property.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tab8-tab-pane"
              role="tabpanel"
              aria-labelledby="tab8-tab"
              tabIndex="0"
            >
              <div className="row">
                <div className=" col-sm-3">
                  <img
                    src={`${process.env.GATSBY_STATIC_IMAGE_URL}/services/pexels-life-of-pix-2469.jpg`}
                    alt="Concrete Removal"
                    className="img-fluid"
                  />
                </div>
                <div className="mt-3 mt-sm-0 col-sm-9">
                  <p>
                    Old concrete structures or surfaces in {city} that need replacement can rely on our
                    efficient removal services. Our team employs advanced equipment and safe practices to
                    dismantle and dispose of old concrete, preparing the way for fresh installations or
                    landscaping projects. With minimal disruption and utmost professionalism, we ensure a clean
                    slate for your next endeavor.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tab9-tab-pane"
              role="tabpanel"
              aria-labelledby="tab9-tab"
              tabIndex="0"
            >
              <div className="row">
                <div className=" col-sm-3">
                  <img
                    src={`${process.env.GATSBY_STATIC_IMAGE_URL}/services/Counter.jpg`}
                    alt="Concrete Bars & Counters"
                    className="img-fluid"
                  />
                </div>
                <div className="mt-3 mt-sm-0 col-sm-9">
                  <p>
                    Elevate the interiors of your {city} property with our bespoke concrete bar and counter
                    solutions. Perfect for homes, restaurants, or commercial spaces, our counters and bars blend
                    modern aesthetics with resilience. Each piece is crafted to your specifications, ensuring a
                    centerpiece that is both functional and artistically captivating.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="galleryPhotos">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="owl-carousel owl-theme galleryContainer">
                <Slider {...settings}>
                  <div className="item">
                    <a href="images/1.jpg" data-lightbox="gallery">
                      <img src={process.env.GATSBY_WRANGLER_URL + "/panel-300x300.jpg"} alt="" />
                    </a>
                  </div>
                  <div className="item">
                    <a href="images/2.jpg" data-lightbox="gallery">
                      <img src={process.env.GATSBY_WRANGLER_URL + "/panel2-300x300.jpg"} alt="" />
                    </a>
                  </div>
                  <div className="item">
                    <a href="images/3.jpg" data-lightbox="gallery">
                      <img src={process.env.GATSBY_WRANGLER_URL + "/panel3-300x300.jpg"} alt="" />
                    </a>
                  </div>
                  <div className="item">
                    <a href="images/1.jpg" data-lightbox="gallery">
                      <img src={process.env.GATSBY_WRANGLER_URL + "/panel-300x300.jpg"} alt="" />
                    </a>
                  </div>
                  <div className="item">
                    <a href="images/2.jpg" data-lightbox="gallery">
                      <img src={process.env.GATSBY_WRANGLER_URL + "/panel2-300x300.jpg"} alt="" />
                    </a>
                  </div>
                  <div className="item">
                    <a href="images/3.jpg" data-lightbox="gallery">
                      <img src={process.env.GATSBY_WRANGLER_URL + "/panel3-300x300.jpg"} alt="" />
                    </a>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="AIArticleSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="mb-4 text-center">Using AI to find the Best Concrete Companies in {city}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <p>
                We use the newest technology to connect you to skilled pros - well known companies, talented
                individuals, proven up-and-comers. You get to decide what’s most important when balancing cost,
                quality, and turnaround time.
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Whether you're renovating, building, or simply upgrading, quality concrete work can elevate the
                aesthetic and functionality of your home. In the vibrant heart of {region.name}, homeowners
                understand that a solid foundation doesn't just mean stability—it means elevating property
                value, enhancing quality of life, and creating spaces that will last for generations.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-4">
              <h5 className="fs-5 fw-bold mb-3">Top Questions Every {city} Homeowner Asks</h5>

              <div className="mb-3">
                <p className="mb-0 fw-bold">1. What Affects the Cost of a Concrete Project?</p>
                <p>
                  The size of the project, customization levels, and the specific requirements dictate cost.
                  It's always wise to get a detailed quote to understand your investment.
                </p>
              </div>
              <div className="mb-3">
                <p className="mb-0 fw-bold">2. How Long Before My Concrete Installation is Ready?</p>
                <p>
                  Typically, 7 days is a safe bet for curing, but it can vary. Some finishes or custom works
                  might require slightly longer times to achieve optimal strength.
                </p>
              </div>
              <div className="mb-3">
                <p className="mb-0 fw-bold">3. Can I Customize My Concrete Installation?</p>
                <p>
                  Definitely! There's a myriad of options, from colors to patterns, allowing you to tailor your
                  installation to your home's unique flair.
                </p>
              </div>
              <div className="mb-3">
                <p className="mb-0 fw-bold">4. How Durable is Concrete?</p>
                <p>
                  When done right, concrete structures can last decades. It's not just about setting and
                  forgetting; it's about creating a legacy.
                </p>
              </div>
              <div className="mb-3">
                <p className="mb-0 fw-bold">5. Maintenance: What Should I Know?</p>
                <p>
                  Seal regularly, avoid deicers in winter, and keep it clean. With proper care, your
                  installation can look new for years.
                </p>
              </div>
              <div className="mb-3">
                <p className="mb-0 fw-bold">6. Concrete Services {city} Homeowners Seek Most</p>
                <p>
                  From foundational work to aesthetic installations, here are the services that homeowners in{" "}
                  {city} are frequently seeking:
                </p>
                <ul>
                  <li>Driveway installations & upgrades</li>
                  <li>Stamped concrete for personalized finishes</li>
                  <li>Patios & outdoor living spaces</li>
                  <li>Concrete repair & maintenance</li>
                  <li>Foundational & structural work</li>
                  <li>Sealing & protective finishes</li>
                  <li>Walkways, paths & garden features</li>
                  <li>Environmentally-friendly concrete solutions</li>
                </ul>
              </div>
              <div className="mb-3">
                <p className="mb-0 fw-bold">7. Why Quality Concrete Matters</p>
                <p>
                  In a study, properties in {region.name} with high-quality concrete installations saw a 5-7%
                  increase in market value. More than the numbers, homeowners reported:
                </p>
                <ul>
                  <li>
                    <strong>Durability:</strong> Concrete structures that withstand the test of time and
                    elements.
                  </li>
                  <li>
                    <strong>Enhanced Aesthetics:</strong> Beautiful finishes that give a touch of luxury.
                  </li>
                  <li>
                    <strong>Increased Property Value:</strong> Solid ROI for homeowners looking at both living
                    and future selling potential.
                  </li>
                  <li>
                    <strong>Improved Quality of Life:</strong> Functional spaces like patios and driveways that
                    families truly enjoy.
                  </li>
                </ul>
              </div>
              <div className="mb-3">
                <p className="mb-0 fw-bold">8. Choosing {city}'s Finest</p>
                <p>
                  Concrete isn't just about pouring and setting; it's an art and a science. The right company
                  understands the local climate, the nuances of the land, and the aspirations of its residents.
                  When you invest in quality, you're not just getting concrete; you're getting peace of mind and
                  a promise of excellence.
                </p>
              </div>
              <div className="mb-3">
                <p className="mb-0 fw-bold">9. Ready for a Home Transformation?</p>
                <p>
                  Your {city} home deserves nothing but the best. Whether you're eyeing a new patio, thinking of
                  reimagining your driveway, or seeking repairs, it's time to act.{" "}
                  <strong>
                    Fill out the form now to get your free, no-obligation quote from the best concrete companies
                    in {city}!
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero2-section" id="contact">
        <div className="container hero2-container position-relative">
          <div className="hero2-left">
            <h2>Get the right concrete company for your {city} home project.</h2>
            <div className="hero2-form">
              <form className="FrmQuote" method="post" onSubmit={handleSubmit}>
                <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <>
                      <div className="input-group">
                        <input
                          {...getInputProps({
                            name: "address",
                            placeholder: "Enter your home address",
                            className: "form-control form-control-lg",
                            id: "heroSectionAddressField",
                            required: "required",
                          })}
                        />
                        {address && (
                          <>
                            <input
                              type="text"
                              name="name"
                              className="form-control form-control-lg inputFieldAfterAddress active"
                              placeholder="Your Name"
                              required
                            />
                            <input
                              type="email"
                              name="email_spam"
                              className="form-control form-control-lg inputFieldAfterAddress active"
                              placeholder="Email"
                              required
                            />
                            <input type="email" name="email" className="d-none" />
                          </>
                        )}
                        <button className="btn btn-lg btnSearch btnSubmit" type="submit" disabled={isLoading}>
                          {isLoading && "Sending..."}
                          {!isLoading && (
                            <>
                              Get My Price Quote <i className="bi bi-check2" />
                            </>
                          )}
                        </button>
                        <button type="reset" className="btnFormReset d-none">
                          Reset
                        </button>
                        <input type="hidden" name="timezone" defaultValue={extraInformation.timeZone} />
                        <input type="hidden" name="language" defaultValue={extraInformation.language} />
                        <input type="hidden" name="capturepage" defaultValue={extraInformation.currentUrl} />
                        <input
                          type="hidden"
                          name="browserResolution"
                          defaultValue={extraInformation.browserResolution}
                        />
                        <input type="hidden" name="form_type" defaultValue="B2B" />
                        <input name="formId" id="formId" defaultValue="13F110" type="hidden" />
                        <input name="referer" defaultValue="https://swiftlocal.com/" type="hidden" />
                        <input name="spam_reduction" defaultValue="https://swiftlocal.com/" type="hidden" />
                      </div>
                      <div className="autocomplete-dropdown-container" style={{ paddingTop: "5px" }}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          let className = "suggestion-item"
                          let style = {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                            padding: "5px",
                            color: "#000",
                            textAlign: "left",
                          }
                          if (suggestion.active) {
                            style.backgroundColor = "#5e9ad7"
                            className = "suggestion-item--active"
                          }
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                            <span>
                              <i className="bi bi-geo-alt-fill" />
                              {` ${suggestion.description}`}
                            </span>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  )}
                </PlacesAutocomplete>
              </form>
            </div>
          </div>
          <div className="hero2-right" />
        </div>
      </section>
      <section className="faq_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="how_it_works_headline text-center">Concrete Services FAQs</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12">
                  <div className="accordion accordion-flush swiftlocal_accordion" id="faqList">
                    <div
                      className="accordion-item"
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                    >
                      <h2 className="accordion-header" id="faq1" itemProp="name">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqBody1"
                          aria-expanded="false"
                          aria-controls="faqBody1"
                        >
                          How long does it take for the concrete to cure?
                        </button>
                      </h2>
                      <div
                        id="faqBody1"
                        className="accordion-collapse collapse"
                        aria-labelledby="faq1"
                        data-bs-parent="#faqList"
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div className="accordion-body" itemProp="text">
                          <p>
                            Concrete typically takes around 28 days to fully cure and achieve its maximum
                            strength. However, it's usually safe to walk on after 24-48 hours and vehicles can
                            typically be driven on it after a week.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="accordion-item"
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                    >
                      <h2 className="accordion-header" id="faq2" itemProp="name">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqBody2"
                          aria-expanded="false"
                          aria-controls="faqBody2"
                        >
                          What's the difference between concrete and cement?
                        </button>
                      </h2>
                      <div
                        id="faqBody2"
                        className="accordion-collapse collapse"
                        aria-labelledby="faq2"
                        data-bs-parent="#faqList"
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div className="accordion-body" itemProp="text">
                          <p>
                            Cement is a binding ingredient used in both concrete and mortar. Concrete combines
                            cement with sand, aggregate, and water, making it suitable for larger construction
                            projects.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="accordion-item"
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                    >
                      <h2 className="accordion-header" id="faq3" itemProp="name">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqBody3"
                          aria-expanded="false"
                          aria-controls="faqBody3"
                        >
                          Can you pour concrete in the rain?
                        </button>
                      </h2>
                      <div
                        id="faqBody3"
                        className="accordion-collapse collapse"
                        aria-labelledby="faq3"
                        data-bs-parent="#faqList"
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div className="accordion-body" itemProp="text">
                          <p>
                            Rain can negatively impact the finish and strength of the concrete. We typically
                            postpone pouring if heavy rain is expected. If unexpected rain occurs post-pouring,
                            we take measures to protect the concrete until it sets.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="accordion-item"
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                    >
                      <h2 className="accordion-header" id="faq4" itemProp="name">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqBody4"
                          aria-expanded="false"
                          aria-controls="faqBody4"
                        >
                          How often should concrete be maintained?
                        </button>
                      </h2>
                      <div
                        id="faqBody4"
                        className="accordion-collapse collapse"
                        aria-labelledby="faq4"
                        data-bs-parent="#faqList"
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div className="accordion-body" itemProp="text">
                          <p>
                            Concrete requires minimal maintenance with proper installation. Nonetheless, sealing
                            outdoor concrete regularly can protect it from stains, wear, and weather.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="accordion-item"
                      itemScope
                      itemProp="mainEntity"
                      itemType="https://schema.org/Question"
                    >
                      <h2 className="accordion-header" id="faq5" itemProp="name">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faqBody5"
                          aria-expanded="false"
                          aria-controls="faqBody5"
                        >
                          Why is it essential to hire a professional concrete company?
                        </button>
                      </h2>
                      <div
                        id="faqBody5"
                        className="accordion-collapse collapse"
                        aria-labelledby="faq5"
                        data-bs-parent="#faqList"
                        itemScope
                        itemProp="acceptedAnswer"
                        itemType="https://schema.org/Answer"
                      >
                        <div className="accordion-body" itemProp="text">
                          <p>
                            Professional companies ensure accurate mixture, pouring, leveling, and curing. This
                            ensures the safety, aesthetics, and longevity of your concrete structures.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="accordion accordion-flush swiftlocal_accordion" id="faqList2">
                <div
                  className="accordion-item"
                  itemScope
                  itemProp="mainEntity"
                  itemType="https://schema.org/Question"
                >
                  <h2 className="accordion-header" id="faq7" itemProp="name">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqBody7"
                      aria-expanded="false"
                      aria-controls="faqBody7"
                    >
                      Do you offer eco-friendly concrete solutions?
                    </button>
                  </h2>
                  <div
                    id="faqBody7"
                    className="accordion-collapse collapse"
                    aria-labelledby="faq7"
                    data-bs-parent="#faqList2"
                    itemScope
                    itemProp="acceptedAnswer"
                    itemType="https://schema.org/Answer"
                  >
                    <div className="accordion-body" itemProp="text">
                      <p>
                        Yes, we provide concrete options that reduce environmental impact by using recycled
                        materials and reducing CO2 emissions without sacrificing quality.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  itemScope
                  itemProp="mainEntity"
                  itemType="https://schema.org/Question"
                >
                  <h2 className="accordion-header" id="faq8" itemProp="name">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqBody8"
                      aria-expanded="false"
                      aria-controls="faqBody8"
                    >
                      What certifications do your technicians hold?
                    </button>
                  </h2>
                  <div
                    id="faqBody8"
                    className="accordion-collapse collapse"
                    aria-labelledby="faq8"
                    data-bs-parent="#faqList2"
                    itemScope
                    itemProp="acceptedAnswer"
                    itemType="https://schema.org/Answer"
                  >
                    <div className="accordion-body" itemProp="text">
                      <p>
                        Our technicians are certified by leading industry institutions, ensuring they're
                        up-to-date with the latest techniques and safety protocols.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  itemScope
                  itemProp="mainEntity"
                  itemType="https://schema.org/Question"
                >
                  <h2 className="accordion-header" id="faq9" itemProp="name">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqBody9"
                      aria-expanded="false"
                      aria-controls="faqBody9"
                    >
                      How much does a typical concrete installation cost?
                    </button>
                  </h2>
                  <div
                    id="faqBody9"
                    className="accordion-collapse collapse"
                    aria-labelledby="faq9"
                    data-bs-parent="#faqList2"
                    itemScope
                    itemProp="acceptedAnswer"
                    itemType="https://schema.org/Answer"
                  >
                    <div className="accordion-body" itemProp="text">
                      <p>
                        The cost varies based on the size, complexity, and customizations of the project. For a
                        tailored quote suited to your needs,{" "}
                        <strong>
                          <a href="#quote-form">fill out our form</a>
                        </strong>{" "}
                        or give us a call today!
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  itemScope
                  itemProp="mainEntity"
                  itemType="https://schema.org/Question"
                >
                  <h2 className="accordion-header" id="faq10" itemProp="name">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqBody10"
                      aria-expanded="false"
                      aria-controls="faqBody10"
                    >
                      How quickly can you start once we decide?
                    </button>
                  </h2>
                  <div
                    id="faqBody10"
                    className="accordion-collapse collapse"
                    aria-labelledby="faq10"
                    data-bs-parent="#faqList2"
                    itemScope
                    itemProp="acceptedAnswer"
                    itemType="https://schema.org/Answer"
                  >
                    <div className="accordion-body" itemProp="text">
                      <p>
                        Our team is ready to get started as soon as you are. Scheduling depends on our current
                        project load, but we always aim to begin new projects promptly.{" "}
                        <strong>
                          <a href="#quote-form">Request a quote</a>
                        </strong>{" "}
                        to discuss timelines!
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  itemScope
                  itemProp="mainEntity"
                  itemType="https://schema.org/Question"
                >
                  <h2 className="accordion-header" id="faq11" itemProp="name">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqBody11"
                      aria-expanded="false"
                      aria-controls="faqBody11"
                    >
                      Do you offer warranties on your work?
                    </button>
                  </h2>
                  <div
                    id="faqBody11"
                    className="accordion-collapse collapse"
                    aria-labelledby="faq11"
                    data-bs-parent="#faqList2"
                    itemScope
                    itemProp="acceptedAnswer"
                    itemType="https://schema.org/Answer"
                  >
                    <div className="accordion-body" itemProp="text">
                      <p>
                        Absolutely! We stand by our work and offer warranties to ensure your peace of mind. Want
                        to learn more? <strong>Give us a call!</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="popularServices mb-60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center mb-md-5 mb-3">
              <h2>Related Services in {locationName}</h2>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0">
              <div className="card">
                <Link to={basePath + "/landscape"}>
                  <img
                    src={process.env.GATSBY_STATIC_IMAGE_URL + "/landscape.jpg"}
                    className="img-fluid"
                    alt="Landscaping"
                  />
                </Link>
                <div className="card-body p-0">
                  <h4 className="card-title mb-0 text-center">
                    <Link to={basePath + "/auto-body"} className="d-block py-4">
                      <strong>{locationName} Landscaping</strong>
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0">
              <div className="card">
                <Link to={basePath + "/mason"}>
                  <img
                    src={process.env.GATSBY_STATIC_IMAGE_URL + "/mason.jpg"}
                    className="img-fluid"
                    alt="Masonry"
                  />
                </Link>
                <div className="card-body p-0">
                  <h4 className="card-title mb-0 text-center">
                    <Link to={basePath + "/oil-change"} className="d-block py-4">
                      <strong>{locationName} Masonry</strong>
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="card">
                <Link to={basePath + "/fences"}>
                  <img
                    src={process.env.GATSBY_STATIC_IMAGE_URL + "/fence.jpg"}
                    className="img-fluid"
                    alt="Fencing"
                  />
                </Link>
                <div className="card-body p-0">
                  <h4 className="card-title mb-0 text-center">
                    <Link to={basePath + "/tire-shops"} className="d-block py-4">
                      <strong>{locationName} Fencing</strong>
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ConcretePage

export const cityQuery = graphql`
  query {
    allRegion {
      nodes {
        id
        code
        name
        countryCode
      }
    }
    allCity {
      nodes {
        id
        name
        latitude
        longitude
      }
    }
  }
`
